var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app"
  }, [_c('AppHeader'), _c('div', {
    staticClass: "app-body"
  }, [_c('Sidebar', {
    attrs: {
      "navItems": _vm.nav
    }
  }), _c('main', {
    staticClass: "main bg-gray-50"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('router-view')], 1)])], 1), _c('AppFooter')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }